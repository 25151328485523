@import '../../styles/variaveis.scss';

.buttonPrimary{
    background-color: $purplePrimary !important;
    border: none;
    font-family: 'PlusJakartaSans-Light';
    color: white;
    &:hover{
      color: $pinkSecondary;
      box-shadow: 2px 2px solid $shadowPrimary;
    }
  }