.navbarSection {
    float: left;
    width: 25%;
  }
  
  .contentSection {
    float: left;
    width: 75%;
  }
  
  @media (max-width: 768px) {
    .navbar-section, .content-section {
      float: none;
      width: 100%;
    }
  }

.mainTitle{
  font-size: 30px;
}
  