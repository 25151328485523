@import '../../../styles/variaveis.scss';

@font-face {
    font-family: 'HoldenMedium';
    src: url('../../../assets/fonts/Holden-Medium-trial.ttf');
}

.productionSection {
  .card {
    &.bg-primary {
      //background-color: $navyBlue !important;
      border: none;
      border-radius: 0.25rem;
      color: #fff;
      text-align: center;

      .card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        .card-title {
          margin-bottom: 0.5rem;
          font-size: 1.25rem;
        }

        .card-text {
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
    }
  }
  .underlineTitle {
    position: relative;
    font-family: 'PlusJakartaSans-Medium';
    display: inline-block;
    padding-bottom: 0.5rem; 
    font-size: 28px;
    font-weight: bold; 
    color: $purplePrimary;

    text-shadow: 2px 2px 2px solid $shadowSecondary;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 4px; 
      background: linear-gradient(to right, $purpleSecondary, $lightPrimary); // gradiente
      border-radius: 2px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
  .cardTitle{
    font-family: 'PlusJakartaSans-Medium';
    font-size: 22px;
  }
  th,td{
    font-family: 'PlusJakartaSans-Light';
    font-size: 16px;
  }
}
.textAlerta{
  font-family: 'PlusJakartaSans-Light';
}
#cardsProduction{
  background-color: $purpleSecondary !important;
  color: white;
}