@import '../../../../styles/variaveis.scss';

.actionColumn {
  width: 90px;
}

.button {
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: $shadowPrimary;
  transition: all .5s;
}

.approveButton {
  background-color: $greenPrimary;
  &:hover {
    background: linear-gradient($greenHover, $greenHoverSecond);
    box-shadow: $shadowPrimary;
    transform: scale(1.1);
    transition: all .5s;
  }
}

.rejectButton {
  background-color: $redPrimary;
  &:hover {
    background: linear-gradient($redHover, $redHoverSecond);
    box-shadow: $shadowPrimary;
    transform: scale(1.1);
    transition: all .5s;
  }
}

.historyButton {
  background-color: $purplePrimary;
  color: white;
  &:hover {
    background-color: $purplePrimary;
    color: $pinkSecondary;
    box-shadow: $shadowPrimary;
    transform: scale(1.1);
    transition: all .5s;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .page-item {
    .active .page-link {
      background-color: $purplePrimary !important;
      color: white !important;
      border-color: $purplePrimary !important;
    }
  }

  .page-link {
    color: $purplePrimary;
    border: 1px solid $purplePrimary;
    transition: background-color .5s, color .5s;

    &:hover {
      background-color: $purplePrimary;
      color: $pinkSecondary;
    }
  }
}
