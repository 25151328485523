@import '../../styles/variaveis.scss';

@font-face {
  font-family: 'HoldenMedium';
  src: url('../../assets/fonts/Holden-Medium-trial.ttf');
}

.navbarSection {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  //background-color: $darkSlateBlue;
  z-index: 1000;
  transition: transform 0.3s ease;

  &.closed {
    transform: translateX(-100%);
  }

  &.open {
    transform: translateX(0);
  }
}

.contentSection {
  align-content: left;
  margin-left: 250px;
  padding: 20px;
  width: calc(100% - 250px);
  overflow-y: auto;
  transition: margin-left 0.3s ease, width 0.3s ease;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
}

.cardHeader {
  font-family: 'PlusJakartaSans-Medium' !important;
  color: white;
  background-color: $purplePrimary;
  font-family: 'HoldenMedium', sans-serif;
  font-size: 32px;
  padding: 1rem;
}

.cardBody {
  font-family: Arial, Helvetica, sans-serif;

  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.partnerInfo {
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  font-family: 'PlusJakartaSans-Light'; 

  .textPrimary {
    color: $purplePrimary !important;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      
      flex-wrap: wrap;

      strong {
        margin-right: 0.5rem;
      }
      
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.partnerHeader {
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding: 1rem;
}