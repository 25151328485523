.imageNotFound {
    display: block;
    margin: 0 auto;
    max-width: 50%;
    height: auto;
  }
  
  .notFoundContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    text-align: center;
  }
  