.custom-modal .modal-dialog {
    max-width: 90%;
  }
  
  .topicsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .topicColumn {
    flex: 1;
    min-width: 200px;
  }
  
  .topicCheckbox {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .columnsContainer {
    margin-left: 20px;
    padding-left: 10px;
    border-left: 2px solid #ccc;
  }
  
  .columnCheckbox {
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .columnCheckbox input[type="checkbox"] {
    transform: scale(1.2);
  }
  
  .columnCheckbox input[type="checkbox"]:checked + label::before {
    background-color: #28a745;
    border-color: #28a745;
  }
  