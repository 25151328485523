.contentSection {
    margin-left: 250px;
    padding: 20px;
    width: calc(100% - 250px);
    overflow-y: auto;
    transition: margin-left 0.3s ease, width 0.3s ease;
  
    @media (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }
  
  .menuClosed {
    margin-left: 0;
    width: 100%;
  }  