@import '../../styles/variaveis.scss';

.buttonDanger{
    background-color: $redPrimary !important;
    border: none;
    font-family: 'PlusJakartaSans-Light';
    color: white;
    transition: all .5s;
    &:hover {
        background: linear-gradient($redHover, $redHoverSecond);
        box-shadow: $shadowPrimary;
        transition: all .5s;
        color: white;
      }
  }