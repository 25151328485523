@import '../../../../styles/variaveis.scss';

.modalStyles {
    color: $purplePrimary !important;
    border-radius: 40px;
}

.headerBonusList {
    font-family: 'PlusJakartaSans-Medium';
    color: $purplePrimary !important;
}

.sectionBonusList {
    background-color: $lightSecondary;
    color: $purplePrimary !important;
    font-family: 'PlusJakartaSans-Light';
}

.titleItemBonus {
    font-family: 'PlusJakartaSans-Bold' !important;
    color: $purplePrimary !important;
}

.bonusItem {
    color: $purplePrimary !important;
}

.list-group-item {
    color: $purplePrimary !important;
}
.active {
    background-color: $purplePrimary !important;
    color: white !important;
    border-color: $purplePrimary !important;
}

.tab-content {
    color: $purplePrimary !important;
}

.table {
    color: $purplePrimary !important;
}

.table td, .table th {
    color: $purplePrimary !important;
}
