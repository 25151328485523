@import '../../styles/variaveis.scss';

.navContainer {
  display: flex;
  height: 10vh;
  flex-direction: column;
  font-family: 'PlusJakartaSans-Light';
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: $purplePrimary;
  color: white;
  transition: transform 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.closed {
  transform: translateX(-100%);
}

.open {
  transform: translateX(0);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: $purplePrimary;
}

.logo {
  width: 140px;
}

.menuIcon {
  cursor: pointer;
  font-size: 24px;
  color: white;
  margin-top: -130px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: -30px;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 10px;
  color: white;
  text-decoration: none;
  transition: background-color 0.2s;
}

.menuItem:hover {
  background-color: $purpleSecondary;
}

.icon {
  margin-right: 10px;
}

.text {
  display: inline-block;
}

.footer {
  padding: 15px;
  text-align: center;
  font-size: 13px;
  display: none;
}

.mobileFooterContent {
  display: flex;
  flex-direction: column;
}

.btnLogOut {
  background-color: white;
  //color: $darkSlateBlue;
  padding: 5px;
  transition: transform 0.3s;
  border: none;
  cursor: pointer;
}

.btnLogOut:hover {
  transform: scale(1.1);
}

.menuToggle {
  position: fixed;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1100;
}

.topBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: $purplePrimary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
  color: white;
  transition: left 0.3s ease;
}

.topBar.menuOpen {
  left: 250px;
  width: calc(100% - 250px);
}

.userSection {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.userName {
  margin-right: 20px;
}

@media (max-width: 768px) {
  .topBar {
    display: none;
  }

  .footer {
    display: block;
  }

  .sidebar {
    width: 100%;
  }

  .menuToggle {
    display: block;
  }
}
