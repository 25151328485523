// Pagination.module.scss
// PaginationStyles.scss

@import '../../styles/variaveis.scss';

$activeColor: $purplePrimary;
$inactiveColor: white;
$hoverColor: $pinkSecondary;

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;

  .paginationItem {
    cursor: pointer;
    padding: 10px 15px;
    margin: 0 5px;
    color: black !important;
    border: 1px solid $inactiveColor;
    border-radius: 4px;
    background-color: white;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: $hoverColor;
      color: white !important;
    }
  }

  .active {
    background-color: $activeColor;
    color: white !important;
    border: 1px solid $activeColor;
    z-index: 3;
  }
}
