@import '../../styles/variaveis.scss';

.mainSectionRegistration {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%; // ocupa toda a largura
  height: 100%; // ocupa toda a altura
  //background: linear-gradient(to right, $navyBlue, $royalBlue); 
  background-size: cover; 
  padding-top: 2%;
  overflow-y: auto;
}


.containerRegistration {
  //background-color: $white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7); 
  display: flex;
  flex-direction: column;
  width: 90%; // largura do containerRegistration
  max-width: 100%; // largura máxima do containerRegistration
  margin: auto; // centraliza o containerRegistration horizontalmente na página
  border-radius: 5%;
  padding: 2rem; // padding interno para espaçamento
  @media (min-width: 768px) { //estilos para telas maiores que 768px
      width: 50%;
  }

  .headerRegistration {
    display: flex;
    align-items: center;
    justify-content: center; // Center the content horizontally
    margin-bottom: 1rem; // espaço entre o header e o restante do formulário
    position: relative;

    img {
      width: 20%;
      height: auto;
      position: absolute;
      left: 0;
    }

    h5 {
      margin: 0;
      flex-grow: 1;
      text-align: center;
    }
  }
}
