@import '../../styles/variaveis.scss';

.mainSectionLogin {
  background: url('../../assets/images/background-image-login.png') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  padding-top: 4%;
  display: flex;
  justify-content: flex-end;
}

.containerLogin {
    background: linear-gradient(
        to bottom, // Gradiente vai de cima para baixo
        #FFFFFF 0%, // Branco no topo
        $lightPrimary 50%, // $lightPrimary no meio
        rgba($lightPrimary, 0.4) 100% // $lightPrimary com transparência no fundo
    );
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7); 
  font-family: 'PlusJakartaSans-Bold';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 642px;
  height: 800px;
  margin-right: 5%; // Ajusta o container à direita
  border-radius: 20px;
  position: relative; // Para o efeito de degradação
  overflow: hidden; // Para o efeito de degradação
  padding-top: 1rem;
  padding-bottom: 1rem;
}

img {
  max-width: 80%;
  max-height: 100px;
  margin-bottom: 8rem;
}

.btnPolicy {
  background: none;
  text-decoration: underline;
  color: $purplePrimary;
  font-size: 13px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.inputWithIcon {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  .inputGroup {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;

    input {
      width: 100%;
      padding-right: 40px;
      border: 1px solid $purplePrimary;
      border-radius: 5px;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.customCheckboxLabel {
  color: $purplePrimary;
  font-size: 14px;
  margin-top: 1rem;
  align-items: left;
}

.loginSpan {
  color: $purplePrimary;
  font-size: 13px;
  font-weight: bold;
  white-space: nowrap;
}

.form-control {
  height: calc(1.5em + .75rem + 2px); // Ajusta a altura do input
}