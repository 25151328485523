@import '../../../styles/variaveis.scss';

.customModal {
  .modal-content {
    background-color: $lightSecondary;
    color: $purplePrimary;
    font-family: 'PlusJakartaSans-Light';
  }

  .modalTitle {
    color: $purplePrimary;
    font-family: 'PlusJakartaSans-Medium';
  }
}

.backgroundModal {
  background-color: $lightSecondary;
  color: $purplePrimary;
}

.bodyModal {
  font-family: 'PlusJakartaSans-Light' !important;
}
.badgeHistoric{
    background-color: $purplePrimary !important;
}
.badgeHistoricRegional{
  background-color: $purpleSecondary !important;
}
.badgeHistoricRegister{
  background-color: $pinkSecondary !important;
}