.finalMessageContainer {
    text-align: center;

  }
  
  .finishImage {
    width: 80%;
    max-width: 600px;
    height: auto;
  }
  
  .redirectButton {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
  }
  /* Ajustes específicos para dispositivos móveis */
  @media (max-width: 768px) {
    .finishImage {
      width: 140%; // Largura maior para dispositivos móveis
      max-width: none;
      position: relative;
      left: 50%;
      transform: translateX(-50%); // Centraliza a imagem
    }
  
    .redirectButton {
      width: 90%; // Largura do botão também ajustada para dispositivos móveis
      font-size: 18px; // Aumentar o tamanho da fonte do botão
    }
  }