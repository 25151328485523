/* Spinner.module.scss */
@import '../../styles/variaveis.scss';

.customSpinner {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner-border {
    border: 0.25em solid transparent;
    border-top: 0.25em solid $purplePrimary; /* Ensure the color is applied */
    border-radius: 50%;
    width: var(--spinner-size, 3rem); /* Default size */
    height: var(--spinner-size, 3rem); /* Default size */
    animation: spinner-border 0.75s linear infinite;
  }

  /* Ensures spinner styles override Bootstrap defaults */
  .spinner-border {
    border: 0.25em solid transparent !important;
    border-top: 0.25em solid $purplePrimary !important;
  }
}
