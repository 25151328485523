@import 'variaveis.scss';

.navbarSection {
    float: left;
    width: 25%;
  }
  
  .contentSection {
    float: left;
    width: 75%;
    font-family: 'PlusJakartaSans-Light';
  }
  
  @media (max-width: 768px) {
    .navbar-section, .content-section {
      float: none;
      width: 100%;
    }
  }
  .buttonDefault{
   // background-color: $royalBlue;
  }