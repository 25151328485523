@import '../../../styles/variaveis.scss';
.sectionFilter{
  font-family: 'PlusJakartaSans-Light';
  .searchButton {
    background-color: $purplePrimary;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: .5s, box-shadow 0.5s ease;
  
    &:hover {
      background-color: $purplePrimary;
      color: $pinkSecondary;
      box-shadow: 0 4px 8px $shadowSecondary;
      transform: scale(1.2);
      transition: .5s, box-shadow 0.5s ease;
    }
  }
  
  .inputId {
      height: calc(3.5rem + 2px);
      padding: 1rem 0.75rem;
      font-size: 1rem;
  }
  
  .partnerSelectExpanded {
    .react-select__control {
      height: calc(3.5rem + 2px);
      padding: 0 0.75rem;
      font-size: 1rem;
    }
  }  
}
