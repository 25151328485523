

//Purple
$purplePrimary : #1B096B;
$purpleSecondary: #6832E0;
$purpleTertiary: #E7D6FD;

//Shadow
$shadowPrimary: #101010;
$shadowSecondary: #0F0F0F;
$shadowTertiary: #505050;

//Light
$lightPrimary: #D9D9D9;
$lightSecondary: #F2F2F2;

//Pink
$pinkPrimary: #57005B;
$pinkSecondary: #BF047E;
$pinkTertiary: #FBCAD7;

//Red
$redPrimary: #DC2626;
$redHover: #FF8452;
$redHoverSecond: #930708;

//Green
$greenPrimary: #34D399;
$greenHover: #88C947;
$greenHoverSecond: #154F04;

//Fontes

@font-face {
    font-family: 'PlusJakartaSans-Light';
    src: url(../assets/fonts/PlusJakartaSans-Light.ttf);
}

@font-face {
    font-family: 'PlusJakartaSans-Bold';
    src: url(../assets/fonts/PlusJakartaSans-Bold.ttf);
}

@font-face {
    font-family: 'PlusJakartaSans-Medium';
    src: url(../assets/fonts/PlusJakartaSans-Medium.ttf);
}




/*
$aquaBlue : #52bfcc; //azul fraco
$navyBlue : #1362a7; //azul primário
$midnightBlue : #014872; //azul mais escuro 
$royalBlue : #154798; //azul secundário
$darkSlateBlue : #1d2345; //azul secundário
$white: #fff;*/