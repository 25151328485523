@import '../../styles/variaveis.scss';

.titleContent{
    font-size: 22px !important;
}
.buttonAdd{
    //background-color: $royalBlue !important;
}
.actionButtons{
    text-align: center;
    margin-top: 8%;
}
