@import '../../../styles/variaveis.scss';

.underlineTitle {
  position: relative;
  font-family: 'PlusJakartaSans-Medium';
  display: inline-block;
  padding-bottom: 0.5rem; 
  font-size: 28px;
  font-weight: bold; 
  color: $purplePrimary;

  text-shadow: 2px 2px 2px solid $shadowSecondary;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 4px; 
    background: linear-gradient(to right, $purpleSecondary, $lightPrimary); // gradiente
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}
Table{
  font-family: 'PlusJakartaSans-Light';
}
.tituloCard{
  font-family: 'PlusJakartaSans-Medium';
}
.iconSpan {
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    font-size: 24px;
    border-radius: 4px;
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
.btnInfo{
  background-color: $purplePrimary;
  color: white;
  transition: .5s all;
  &:hover{
    background-color: $purplePrimary;
    box-shadow: 1px 2px 3px solid $shadowPrimary;
    color: $pinkSecondary;
    transition: .5s all;
    transform: scale(1.1);
  }
}
