@import '../../../../styles/variaveis.scss';

.modalStyles {
  color: $purplePrimary !important;
  border-radius: 40px;
}

.conditionsBackgroundModal {
  background-color: $lightSecondary;
  color: $purplePrimary !important;
  font-family: 'PlusJakartaSans-Light';
}

.headerConditionsList {
  font-family: 'PlusJakartaSans-Medium';
  color: $purplePrimary !important;
}

.tabContent {
  font-family: 'PlusJakartaSans-Light';
  color: $purplePrimary !important;
}

.navTabs {
  color: $purplePrimary;
  font-family: 'PlusJakartaSans-Light' !important;
  &:focus {
    background-color: $purplePrimary !important;
    color: white !important;
    font-weight: bold !important;
  }
}

th, td {
  color: $purplePrimary !important;
}
