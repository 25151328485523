@import '../../styles/variaveis.scss';

.buttonLight{
    background-color: $lightPrimary !important;
    border: none;
    font-family: 'PlusJakartaSans-Light';
    color: $shadowSecondary;
   
    &:hover{
      color: $shadowPrimary;
     
      box-shadow: 2px 2px solid $shadowPrimary;
    }
  }