@import '../../../../styles/variaveis.scss';

.customModal {
  .modal-content {
    background-color: $lightSecondary;
    color: $purplePrimary;
    font-family: 'PlusJakartaSans-Light';
  }

  .modalTitle {
    color: $purplePrimary;
    font-family: 'PlusJakartaSans-Medium';
  }
}

.backgroundModal {
  background-color: $lightSecondary;
  color: $purplePrimary;
}

.bodyModal {
  font-family: 'PlusJakartaSans-Light';

  textarea:focus {
    border-color: $purplePrimary;
    box-shadow: 0 0 0 0.2rem rgba($purplePrimary, 0.25);
  }
}
