@import '../../../styles/variaveis.scss';

.validateCodeContainer {
    display: flex;
    flex-direction: column;
    align-items: center; // Centraliza o conteúdo
    padding: 2rem; // Adiciona espaçamento ao redor

    .codeInputs {
        display: flex;
        justify-content: center; // Centraliza os inputs
        margin: 1rem 0; // Espaçamento vertical
        margin-top: 5%;
    }

    .codeInput {
        width: 40px; // Define a largura ideal dos inputs
        height: 40px; // Define a altura dos inputs
        margin: 0 5px; // Espaço entre os inputs
        padding: 10px; // Adiciona padding interno
        //border: 1px solid $midnightBlue; // Borda com a cor desejada
        border-radius: 5px; // Bordas arredondadas

        &:focus {
            outline: none; // Remove contorno padrão
            //border-color: $royalBlue; // Borda azul ao focar
            //box-shadow: 0 0 5px $aquaBlue; // Sombra para destaque
        }

        &.isInvalid {
            border-color: red;
        }

        &.isValid {
            border-color: green;
        }
    }

    .actions {
        margin-top: 1rem; // Espaço acima dos botões
    }

    .resendButton {
        background-color: transparent; // Botão link
       // color: $navyBlue; // Cor do texto
        border: none; // Sem borda
        cursor: pointer; // Muda o cursor para indicar que é clicável
        text-decoration: underline; // Destaca como link
    }

    .backButton {
        margin-top: 1rem; // Espaço acima do botão
        margin-right: 1rem;
        padding: 10px; // Adiciona padding
        border-radius: 5px; // Bordas arredondadas
        //background-color: $darkSlateBlue; // Cor do botão secundário
        //color: $white; // Cor do texto
        &:hover {
            //background-color: $royalBlue; // Cor ao passar o mouse
        }
    }

    .emailActions {
        display: flex;
        align-items: center; // Alinha verticalmente
        margin-top: 1rem; // Espaço acima
    }

    .emailText {
        margin-right: 1rem; // Espaço entre o texto do email e o botão
    }
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

.shake {
    animation: shake 0.5s;
}
