@import '../../styles/variaveis.scss';

.buttonSecondary{
    background-color: $pinkPrimary !important;
    border: none;
    font-family: 'PlusJakartaSans-Light';
    color: white;
    &:hover{
      color: $pinkSecondary;
      box-shadow: 2px 2px solid $shadowPrimary;
    }
  }