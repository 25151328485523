@import '../../../../styles/variaveis.scss';

.select {
    .react-select__control {
      height: 3rem;
      padding: 0 0.75rem;
      font-size: 1rem;
    }
  
    .react-select__menu {
      width: 100% !important;
    }
  
    .react-select__menu-list {
      max-height: 15rem;
      overflow-y: auto;
    }
  }  

.sectionFilter{
  font-family: 'PlusJakartaSans-Light';
}