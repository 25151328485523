@import '../../../styles/variaveis.scss';

.availableConventionsSection {
  font-family: 'PlusJakartaSans-Light';

  .tableContainer {
    height: 350px;
    overflow-y: auto;
  }
  .underlineTitle {
    position: relative;
    font-family: 'PlusJakartaSans-Medium';
    display: inline-block;
    padding-bottom: 0.5rem; 
    font-size: 28px;
    font-weight: bold; 
    color: $purplePrimary;

    text-shadow: 2px 2px 2px solid $shadowSecondary;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 4px; 
      background: linear-gradient(to right, $purpleSecondary, $lightPrimary); // gradiente
      border-radius: 2px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
  
}

h4{
  font-family: 'PlusJakartaSans-Medium';
}